import React from 'react';

const Privacy = (props) => {
  return (
    <div>
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy describes how Aayhova Technologies Pvt Ltd ("the Company," "we," "us," or "our") collects, uses, and discloses information collected from users ("you" or "your") of the IOVA mobile application ("the App"). By using the App, you agree to the terms of this Privacy Policy.</p>
        
        <h2>1. Information We Collect</h2>
        <p>1.1. Information You Provide: When you register an account on IOVA, we may collect personal information such as your name, email address, phone number, and payment details.</p>
        <p>1.2. Communications: We may collect information from your communications with us, including messages, emails, and feedback.</p>
        <p>1.3. Usage Information: We collect information about how you interact with the App, such as the pages you visit, the features you use, and the actions you take.</p>
        <p>1.4. Uploaded Files: During the use of the App, you may upload files or documents necessary for the completion of financial services orders. While we do not read or collect information from these files other than basic file attributes, users are advised to exercise caution when uploading sensitive information.</p>
        
        <h2>2. How We Use Your Information</h2>
        <p>2.1. We use the information collected to provide, maintain, and improve the functionality of the App.</p>
        <p>2.2. Your personal information may be used to communicate with you, respond to your inquiries, and provide customer support.</p>
        
        <h2>3. Information Sharing and Disclosure</h2>
        <p>3.1. We may share your information with third-party service providers who assist us in providing the App and its features. For example, we may use your phone number to send One-Time Passwords (OTPs) through third-party messaging services to verify your identity and ensure the security of your account.</p>
        <p>3.2. We may disclose your information in response to legal requests, such as court orders or subpoenas, or to comply with applicable laws and regulations.</p>
        <p>3.3. In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</p>
        <p>3.4. We never sell your data to third parties or intend to profit from your data. Your privacy is important to us, and we are committed to protecting your information.</p>
        
        <h2>4. Data Security</h2>
        <p>4.1. We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, alteration, or destruction.</p>
        <p>4.2. Despite our efforts, no security measures are perfect or impenetrable, and we cannot guarantee the security of your information.</p>
        
        <h2>5. Your Choices</h2>
        <p>5.1. You can access and update your account information through the App's settings.</p>
        
        <h2>6. Children's Privacy</h2>
        <p>6.1. The App is not intended for children under the age of 18 years. We do not knowingly collect personal information from children under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us to request deletion of the information.</p>
        
        <h2>7. User Account Management and Fraud Detection</h2>
        <p>7.1. Account Deletion: Users have the ability to delete their account and associated information through our website. Upon deletion, all personal information stored in our database will be permanently removed, except for any data that we are required to retain for legal or regulatory compliance purposes.</p>
        <p>7.2. Fraud Detection: To ensure the security and integrity of our platform, we may store certain metadata specifically for advisors for fraud detection purposes. This metadata may include information related to user interactions, order history, and account activity. This data is used solely for fraud detection and prevention and is not shared with third parties except as required by law.</p>
        
        <h2>8. Changes to this Privacy Policy</h2>
        <p>8.1. We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on the App or by other means of communication. Your continued use of the App after such changes constitutes acceptance of the updated Privacy Policy.</p>
        
        <h2>9. Contact Us</h2>
        <p>9.1. If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:ayanjit.das39@gmail.com">ayanjit.das39@gmail.com</a> or call us at 9434106863. You can also visit our office at B178/104, Sapoorji Complex, NewTown, Kolkata, 700135.</p>
    </div>
);
}

export default Privacy;
