import React from 'react';
import FeatureBox from './FeatureBox';
import featureimage from '../images/IOVA_Search_Advisor.png';
import featureimage1 from '../images/IOVA_connect.png';
import featureimage2 from '../images/IOVA_Confirm_Order.png';
import iovaGPT from '../images/IOVA_Chat_Gpt.png';

const Feature = () => {
  return (
    <div id='features'>
        <div className='a-container'>
            <FeatureBox image={featureimage} title='Search Advisor' subTitle='Pick your advisor as per your need with our efficient search!'></FeatureBox>
            <FeatureBox image={featureimage1}  title='Get Connected' subTitle='Seamless connection with your selected advisor!'></FeatureBox>
            <FeatureBox image={featureimage2} title='Confirm Order' subTitle='Get the quotation for your tasks and place your order with couple of clicks!'></FeatureBox>
            <FeatureBox image={iovaGPT} redirectUrl={'https://chat.iova.app/'} title='iovaGPT (Financial Chat)' subTitle='Your own tailored chat bot for any Indian tax related queries!'></FeatureBox>
        </div>
    </div>
  )
}

export default Feature