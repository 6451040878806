import React from 'react';

const About = (props) => {
  return (
    <div id='about'>
      <div className='about-image'>
        {/* <img src={props.image} alt=''></img> */}
      </div>
      <div className='about-text'>
        <h2> {props.title} </h2>
        <p>Discover the future of financial empowerment with IOVA. Our user-friendly platform connects common people with certified financial advisors who can provide tailored solutions to meet unique financial goals. IOVA also allows financial advisors to expand their client base and grow their practice.</p>
        <a href="https://eco-apks-distribution.s3.ap-south-1.amazonaws.com/iova_1_3_2024.apk">
          <button> {props.button} </button>
        </a>
        
      </div>
    </div>
  )
}

export default About;
