import React from 'react';

const ClientTnC = (props) => {
  return (
    <div>
        <h1>Welcome to IOVA</h1>
        <p>provided by Aayhova Technologies Pvt Ltd.</p>
        <p>These terms and conditions ("Terms") govern your use of IOVA as a client seeking financial advice through the app. By accessing or using the IOVA app, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the app.</p>
        
        <h2>1. Account Creation and Usage</h2>
        <p>1.1. Before searching for advisors for financial advice, clients must create an account on IOVA. Clients are required to provide accurate and complete information during the registration process.</p>
        <p>1.2. Clients are responsible for maintaining the confidentiality of their account credentials, including passwords and OTPs (One-Time Passwords). Clients must not share OTPs or other account authentication information to prevent unauthorized access and compromise of their accounts.</p>
        
        <h2>2. Services Offered</h2>
        <p>2.1. IOVA allows clients to connect with Financial Advisors to seek financial services. As a client, you can create orders for various financial tasks and manage your requests through the app.</p>
        <p>2.2. Clients should communicate with advisors professionally and refrain from using any inappropriate language or behavior during interactions.</p>
        <p>2.3. When creating a connection request, clients are encouraged to provide a descriptive overview of the type of advice they are seeking. Providing detailed information will help advisors better assess the request and provide relevant assistance.</p>
        <p>2.4. After an advisor accepts a connection request, clients are required to carry out their orders exclusively within the app. Clients are prohibited from seeking advice directly from the advisor outside of the app and paying them directly. Any violation of this policy will result in termination of the client's account.</p>
        
        <h2>3. Financial Transactions</h2>
        <p>3.1. Clients are required to pay the order fee through the app to approve an order. Payments for services rendered by Financial Advisors must be made exclusively through the app's designated payment methods.</p>
        <p>3.2. In the event of a refund, clients need to wait for a period of 7-14 days for the bank to process the refund. Refunds will be issued through the same payment method used for the initial transaction.</p>
        <p>3.3. If an advisor requests payment outside of the app or asks for any additional fees, clients must notify Aayhova Technologies Pvt Ltd immediately. Clients are prohibited from making any payments directly to advisors outside of the app. Any violation of this policy will result in termination of the client's account.</p>
        
        <h2>4. Client Conduct</h2>
        <p>4.1. You agree to use IOVA in compliance with all applicable laws, regulations, and these Terms. Any misuse of the app or violation of these Terms may result in the termination of your account.</p>
        
        <h2>5. Privacy</h2>
        <p>5.1. By agreeing this terms and conditions you are agreeing the <a href="https://iova.app/PrivacyPolicy">Privacy Policy</a> </p>
        <p>5.2. Aayhova Technologies Pvt Ltd is committed to protecting the privacy of our clients. Our Privacy Policy outlines how we collect, use, and disclose your information. By using the IOVA app, you consent to the practices described in the Privacy Policy.</p>
        <p>5.3. During an order, the files clients upload to advisors are private. We do not read or collect any information from the files other than basic file attributes such as file size and file name. However, clients are advised to exercise caution when uploading files. Clients should only upload files that are essential for the completion of the order because advisors have access to the files attached in the order. In the event that a client uploads sensitive information, Aayhova Technologies Pvt Ltd is not liable.</p>
        <p>5.4. Clients are prohibited from uploading any content that is not related to the financial advice they are seeking in the order or in the cloud file storage provided by the app. Any client found uploading unrelated content will be banned from using the app.</p>
        <p>5.5. In case a client uploads any illegal image, audio, or video content, they will be banned from using the app and reported to local law enforcement authorities.</p>

        <h2>6. Intellectual Property</h2>
        <p>6.1. The content and features of IOVA are owned by the Company and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the app without prior written permission.</p>
        
        <h2>7. Limitation of Liability</h2>
        <p>7.1. Aayhova Technologies Pvt Ltd provides clients with the ability to connect to advisors for financial advice through the IOVA app. However, the Company does not assume any liability for the tasks that advisors undertake on behalf of the client.</p>
        <p>7.2. The Company shall not be liable for any indirect, consequential, or incidental damages arising from the services provided by advisors through the app. Clients acknowledge that the relationship between the client and the advisor is solely between them, and the Company is not a party to such agreements or arrangements.</p>
        
        <h2>8. Changes to Terms</h2>
        <p>8.1. The Company reserves the right to modify these Terms at any time. We will notify you of any material changes to these Terms. Your continued use of IOVA after such changes constitutes acceptance of the modified Terms.</p>
        
        <h2>9. Contact Us</h2>
        <p>9.1. If you have any questions or concerns about these Terms or the IOVA app, please contact us at <a href="mailto:ayanjit.das39@gmail.com">ayanjit.das39@gmail.com</a> or call us at 9434106863. You can also visit our office at B178/104, Sapoorji Complex, NewTown, Kolkata, 700135.</p>
    </div>
);

}

export default ClientTnC;
