import React from 'react';

const AdvisorTnC = (props) => {
  return (
    <div>
        <h1>Welcome to IOVA</h1>
        <p>provided by Aayhova Technologies Pvt Ltd ("the Company"). These terms and conditions ("Terms") govern your use of IOVA as a financial advisor creating, managing and fulfilling orders for clients. By accessing or using the IOVA app, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the app.</p>
        
        <h2>1. Account Creation and Usage</h2>
        <p>1.1. To access IOVA's features and services as a financial advisor, you must create an advisor account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
        <p>1.2. You must provide accurate and complete information during the registration process and keep your account information up to date.</p>
        
        <h2>2. Services Offered</h2>
        <p>2.1. As a financial advisor on IOVA, you can create orders for various financial tasks and provide services to clients.</p>
        <p>2.2. Advisors must fulfill the orders exclusively through the IOVA app. Any attempt to conduct transactions or accept payments outside of the app is strictly prohibited. Violation of this policy may result in the suspension or banning of the advisor's account.</p>
        <p>2.3. While advisors may communicate directly with clients to understand the services they are seeking, all orders must be initiated, managed, and completed within the IOVA app.</p>
        <p>2.4. Advisors are responsible for understanding the client's requirements, generating the order accordingly, and providing accurate and reliable financial advice and services.</p>
        <p>2.5. Upon the client's approval of the order, advisors can commence work and deliver the requested services within the app's platform.</p>
        
        <h2>3. Financial Transactions</h2>
        <p>3.1. Advisors are strictly prohibited from accepting payments directly from clients outside of the IOVA app. Any attempt to do so will result in the banning of the advisor's account.</p>
        <p>3.2. Advisors will be eligible for payment from a specific order only after they have fulfilled it to the satisfaction of the client and provided completion proof that is approved by the client. Payments to the advisor will be processed by Aayhova Technologies Pvt Ltd by the end of each day, bi-weekly, or weekly, depending on the company's operational schedule.</p>
        
        <h2>4. User Conduct</h2>
        <p>4.1. You agree to use IOVA in compliance with all applicable laws, regulations, and these Terms. Any misuse of the app or violation of these Terms may result in the termination of your account.</p>
        
        <h2>5. Privacy</h2>
        <p>5.1. By agreeing this terms and conditions you are agreeing the <a href="https://iova.app/PrivacyPolicy">Privacy Policy</a> </p>
        <p>5.2. Whatever file advisors upload to the cloud file store stays fully private. We do not read or collect any information from the files other than basic file attributes such as file size and file name. However, advisors should exercise caution when attaching files to any order, as the files they upload to orders can be read by clients.</p>
        <p>5.3. Advisors are prohibited from asking or requesting clients to upload any content that is not related to the financial advice they are seeking in the order in the app. Any advisor found asking for unrelated content will be banned from using the app.</p>
        <p>5.4. In case a advisor uploads any illegal image, audio, or video content, they will be banned from using the app and reported to local law enforcement authorities.</p>
        
        <h2>6. Intellectual Property</h2>
        <p>6.1. The content and features of IOVA are owned by the Company and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the app without prior written permission.</p>
        
        <h2>7. Limitation of Liability</h2>
        <p>7.1. Aayhova Technologies Pvt Ltd provides clients with the ability to connect to advisors for financial advice through the IOVA app. However, the Company does not assume any liability for the tasks that advisors undertake on behalf of the client.</p>
        <p>7.2. The Company shall not be liable for any indirect, consequential, or incidental damages arising from the services provided by advisors through the app. Users acknowledge that the relationship between the user and the advisor is solely between them, and the Company is not a party to such agreements or arrangements.</p>
        
        <h2>8. Changes to Terms</h2>
        <p>8.1. The Company reserves the right to modify these Terms at any time. We will notify you of any material changes to these Terms. Your continued use of IOVA after such changes constitutes acceptance of the modified Terms.</p>
        
        <h2>9. Contact Us</h2>
        <p>9.1. If you have any questions or concerns about these Terms or the IOVA app, please contact us at <a href="mailto:ayanjit.das39@gmail.com">ayanjit.das39@gmail.com</a> or call us at 9434106863. You can also visit our office at B178/104, Sapoorji Complex, NewTown, Kolkata, 700135.</p>
    </div>
);

}

export default AdvisorTnC;
