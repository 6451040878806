import React, { useState } from 'react';
import '../Styles/DeleteMyAccount.css'

function DeleteMyAccount() {
    const [userType, setUserType] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [reason, setReason] = useState('');
    const [otp, setOTP] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [buttonLabel, setButtonLabel] = useState('Generate OTP');
    const baseURL = 'https://api.iova.app/api/';

    const handleButtonClick = () => {
      if (buttonLabel === 'Submit') {
        if(!(/^\d{6}$/.test(otp))){
            setErrorMessage('Please enter the proper 6-digit OTP.');
        }
        else{
            deleteUserAccount();
        }
      } 
      else {
        if(userType === ''){
            setErrorMessage('Please select the user type.');
        }
        else if(!(/^\d{10}$/.test(phoneNumber))){
            setErrorMessage('Please enter a valid 10-digit phone number.');
        }
        else if(reason === ''){
            setErrorMessage('Please provide a reason for deleting the account.');
        }
        else{
            generateOTP();
        }
      }
    };
  
    const generateOTP = () => {
      setIsLoading(true);
      setErrorMessage('');
      let _userType = '';
      if(userType === 'Client'){
        _userType = 'user';
      }
      else if(userType === 'Advisor'){
        _userType = 'advisor';
      }
      let _url = baseURL + _userType + '/signin/sendOTP?phoneNumber=' + phoneNumber + '&isVoiceOTP=False';
      setButtonLabel('Submit');
      fetch(_url, {
        method: "GET"
        })
        .then(response => {
          if (response.ok) {
            return;
          } else {
            throw new Error('Error generating OTP');
          }
        })
        .catch(error => {
          setErrorMessage('Failed to generate OTP. Please try again.');
          console.error('Error generating OTP:', error);
          setPhoneNumber('');
          setReason('');
          setOTP('');
          setUserType('');
          setButtonLabel('Generate OTP');
          setDeleteSuccess(false);
        })
        .finally(() => setIsLoading(false));
    };
  
    const deleteUserAccount = () => {
      setIsLoading(true);
      setErrorMessage('');
      // Call external API to delete account
      let _userType = '';
      if(userType === 'Client'){
        _userType = 'user';
      }
      else if(userType === 'Advisor'){
        _userType = 'advisor';
      }
      let _url = baseURL + _userType + '/signin/verifyOTP?phoneNumber=' + phoneNumber + "&OTP=" + otp;
      fetch(_url, {
        method: "GET"
        })
        .then(response => {
            if (response.status===200) {
                return response.json();
            }
            else if (response.status===401) {
                throw new Error('Wrong OTP provided.');
            } else if (response.status!==200){
                throw new Error('Error verifying OTP.');
            }
        })
        .then((data) => {
            console.log(data);
            if(data){
                var endpoint = "";
                if(_userType === 'user'){
                    endpoint = baseURL + 'user/deleteUserAccount'
                }
                else if(_userType === 'advisor'){
                    endpoint = baseURL + 'advisor/deleteAdvisorAccount'
                }
                fetch(endpoint, {
                    method: "PUT",
                    headers: {Authorization: 'Bearer ' + data.accesstoken}
                })
                .then((response) => {
                    console.log(response.status);
                    if(response.status===200){
                        setDeleteSuccess(true);
                        setPhoneNumber('');
                        setReason('');
                        setOTP('');
                        setUserType('');
                        setButtonLabel('Generate OTP');
                    }
                    else {
                        throw new Error('Error deleting account');
                    }
                })
                .catch((error) => {
                    throw new Error('Error deleting account');
                });
            }
        })
      .catch(error => {
        if(error.message === 'Wrong OTP provided.' || error.message === 'Error verifying OTP.'
            ||error.message === 'Error deleting account')
            setErrorMessage('Failed to delete account. Please try again. ' + error.message);
        else
            setErrorMessage('Failed to delete account. Please try again. ');
        console.error('Error deleting account:', error);
        setPhoneNumber('');
        setReason('');
        setOTP('');
        setUserType('');
        setButtonLabel('Generate OTP');
        setDeleteSuccess(false);
      })
      .finally(() => setIsLoading(false));
    };
  
    return (
      <div className="del-body">
        <div className="del-container">
          <h1 className="del-iova-heading">IOVA</h1>
          <h2>Delete User Account</h2>
          <div className="del-input-group">
            <label>Select your user type</label>
            <label>
              <input type="radio" id="client" value="Client" checked={userType === "Client"} disabled = {buttonLabel === 'Submit'} onChange={() => buttonLabel !== 'Submit' && setUserType("Client")} />
              <span onClick={() => buttonLabel !== 'Submit' && setUserType("Client")} className="radio-label">Client</span>
            </label>
            <label>
              <input type="radio" id="advisor" value="Advisor" checked={userType === "Advisor"} disabled = {buttonLabel === 'Submit'} onChange={() => buttonLabel !== 'Submit' && setUserType("Advisor")} />
              <span onClick={() => buttonLabel !== 'Submit' && setUserType("Advisor")} className="radio-label">Advisor</span>
            </label>
          </div>
          <div className="del-input-group">
            <label>
              Phone Number:
              <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} disabled = {buttonLabel === 'Submit'} maxLength={10}/>
            </label>
          </div>
          <div className="del-input-group">
            <label>
              Reason to Delete Account:
              <textarea value={reason} onChange={(e) => setReason(e.target.value)} disabled = {buttonLabel === 'Submit'} maxLength={250}/>
            </label>
          </div>
          <div className="del-input-group">
            <label>
              OTP:
              <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} maxLength={6}/>
            </label>
          </div>
          {deleteSuccess && <p className="del-success-message">{'Your account and all data have been permanently removed.'}</p>}
          {errorMessage && <p className="del-error-message">{errorMessage}</p>}
          <button className="del-generate-btn" onClick={handleButtonClick} disabled={isLoading}>{isLoading ? 'Please wait...' : buttonLabel}</button>
        </div>
      </div>
    );
 }
  
  export default DeleteMyAccount;