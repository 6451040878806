import React, {useState} from 'react'
import logo from '../images/iova-logo-2.png'
import { useNavigate  } from 'react-router-dom';

const NavBar = () => {
    const [navBgr, setNavbgr] = useState(false);
    const changeBackground = () => {
        if(window.screenY >= 50){
            setNavbgr(true);
        }
        else{
            setNavbgr(false);
        }
    }
    
    window.addEventListener('scroll', changeBackground);
    const [activeItem, setActiveItem] = useState('Home');
    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        // Add code here to handle navigation if needed
    };
    const history = useNavigate ();
    const redirectToIOVAGPT = () => {
        window.location.href = 'https://chat.iova.app/';
    };

    return (
        <nav className={navBgr ? 'nav-active' : 'nav'}>
            <a href='#' className='logo'>
                <img src={logo} alt=''></img>
            </a>
            <input type='checkbox' className='menu-btn' id='menu-btn'></input>
            <label className='menu-icon' htmlFor='menu-btn'>
                <span className='nav-icon'></span>
            </label>
            <ul className='menu'>
                <li><a href='#' className={activeItem === 'Home' ? 'active' : ''} onClick={() => handleItemClick('Home')}>Home</a></li>
                <li><a href='#about' className={activeItem === 'About' ? 'active' : ''} onClick={() => handleItemClick('About')}>About</a></li>
                <li><a href='#features' className={activeItem === 'Features' ? 'active' : ''} onClick={() => handleItemClick('Features')}>Features</a></li>
                <li><a onClick={redirectToIOVAGPT}>iovaGPT</a></li>
                {/*<li><a href='#deleteAcc'>Delete Account</a></li>*/}
                {/*<li><a href='#presentaion'>UI SS</a></li>*/}
                <li><a href='#contact' className={activeItem === 'ContactUs' ? 'active' : ''} onClick={() => handleItemClick('ContactUs')}>Contact Us</a></li>
            </ul>
        </nav>
    )
}

export default NavBar;