import React from 'react';
import Navbar from './Navbar';

const Header = () => {
  return (
    <div id="main">
        <Navbar></Navbar>
        <div className='name'>
            <h1><span>Boost Your Financial Cognition. </span>Find your Finance Buddy</h1>
            <p className='details'>Search and get connected with desired advisor/consultant for your better financial future.</p>
            <a href='https://eco-apks-distribution.s3.ap-south-1.amazonaws.com/iova_1_3_2024.apk' className='cv-btn'>Download</a>
        </div>
    </div>
  )
}

export default Header;