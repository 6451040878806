import React from 'react';

const FeatureBox = (props) => {
  return (
    <div className='a-box'>
        <div className='a-b-img'>
            <img src={props.image}></img>
        </div>
        <div className='s-b-text'>
            <h1> {props.title} </h1>
            <h3> {props.subTitle} </h3>
            {props.redirectUrl ? <a href={props.redirectUrl} target="_blank">Visit</a> : <p></p>}
        </div>
    </div>
  )
}

export default FeatureBox