import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./Components/Header";
import Feature from "./Components/Feature";
import About from "./Components/About";
import Presentation from "./Components/Presentation";
import Contact from "./Components/Contact";
import aboutimage from './images/IOVA_about_banner.png';
import DeleteAccount from "./Components/DeleteAccount";
import ClientTnC from "./Components/ClientTnC";
import AdvisorTnC from "./Components/AdvisorTnc";
import Privacy from "./Components/Privacy";
import DeleteMyAccount from "./Components/DeleteMyAccount";

function MainApp (){
  return (
    <div className="App">
      <Header></Header>
      <About image={aboutimage} title='All you need for your finance' button='Get The App'></About>
      <Feature></Feature>
      {/*<Presentation></Presentation>*/}
      {/*<DeleteAccount></DeleteAccount>*/}
      <Contact></Contact>
    </div>
  );
}

function App() {
  return(
    <div>
      <Router>
        <Routes>
        <Route
            exact
            path="/"
            element={<MainApp />}
        />
        <Route
            exact
            path="/ClientTermsAndConditions"
            element={<ClientTnC />}
        />
        <Route
            exact
            path="/AdvisorTermsAndConditions"
            element={<AdvisorTnC />}
        />
        <Route
            exact
            path="/PrivacyPolicy"
            element={<Privacy />}
        />
         <Route
            exact
            path="/DeleteMyAccount"
            element={<DeleteMyAccount />}
        />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
