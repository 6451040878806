import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  env,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faContactBook } from '@fortawesome/free-regular-svg-icons'

const Contact = () => {
  return (
    <div id='contact' className="contactuscontainer">
      <table className="footer">
        <tbody>
          <tr>
            <td>
              <a href="https://www.linkedin.com/company/aayhova-technologies-private-limited/posts/?feedView=all" target="blank" className="facebook social">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="https://twitter.com/iovaapp" target="blank" className="twitter social">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
            </td>
            <td className="footer-right">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
              <span className="footer-cntent">aayhova@iova.app</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="footer-right">
              <FontAwesomeIcon icon={faContactBook} size="2x" />
               <span className="footer-cntent">9434106863</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Contact;